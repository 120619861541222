import { API } from "./API";

// Pendencias do Empreendimento ----------------------------------------------------
const EmpreendimentoPendencias = async (params) => {    
  const lo_params = {params: {params}}
  const resp = await API.get(`/pendencias/empreendimento_pendencias`,
                              lo_params
                            );
  const { data } = resp;
  if (resp.status == 200)
    return data.result;
  else
    return data;
}

export {
  EmpreendimentoPendencias
}
